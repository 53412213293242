import { dirButton } from '../lib/utils';

export default class StateSlider {
	constructor(element, options) {
		this.defaults = { // Defaults
			duration: 425,
			slickOptions: {
				adaptiveHeight: true,
				arrows: true,
				dots: false,
				draggable: false,
				inifinite: true,
				slidesToShow: 5,
				slidesToScroll: 1,
				centerMode: true,
				variableWidth: true,
				speed: 425,
				rows: 0,
				nextArrow: dirButton('', 'btn btn--large btn--rnd', 'Go to the next U.S. state'),
				prevArrow: dirButton('', 'btn btn--large btn--rnd dir-btn--prev', 'Go to the previous U.S. state'),
				responsive: [
					{
						breakpoint: 768,
						settings: {
							draggable: true
						}
					}
				]
			}
		};

		this.element = element;
		this.options = $.extend({}, this.defaults, options);

		this.init();
	}

	init() {
		this.bindUIActions();
	}

	bindUIActions() {
		// Slick Slider events
		$(this.element).on('init', this.onChange.bind(this));
		$(this.element).on('afterChange', this.onChange.bind(this));
		$(this.element).on('breakpoint', this.onBreakpoint.bind(this));
		// Custom events
		$(this.element).on('click', '.state-slider__label', this.onClick.bind(this));
		$(this.element).on('mouseleave', '.state-slider__label', e => $(e.target).blur());
		$(this.element).on('mouseleave', '.dir-btn', e => $(e.target).blur());
		// Initialize
		$(this.element).slick(this.options.slickOptions);
	}

	onBreakpoint(e, slick) {
		const slide = slick.$slides[slick.currentSlide];

		this.toggleTabState($(slide).find('.state-slider__label'));
	}

	onClick(e) {
		e.preventDefault();

		if ($(e.target).hasClass('state-slider__label--is-active')) {
			return;
		}
		
		let index = $(e.target).parent().data('slick-index');
		console.log();		
		// Slick understands that a cloned element of -1 should wrap but does not correctly address values of -2 or more
		console.log(index);
		if (index < -1) {
			index = $(this.element)[0].slick.$slides.length + index;
		}
		console.log(index);

		$(this.element).slick('slickGoTo', index);
	}

	onChange(e, slick, currentSlide) {
		const evt = e.type;
		const slide = (evt === 'init') ? slick.$slides[0] : slick.$slides[currentSlide];

		this.handleStateChange($(slide).find('.state-slider__label'));

		setTimeout(function () {
			$(slide).attr('aria-hidden', false);

			$(slide).attr('aria-label', function () {
				const state = $(slide).find('.state-slider__label .show-for-sr').text();
				return `Current state: ${state}`;
			});
		}, 100);
	}

	handleStateChange(element) {
		const sibling = element.parent().siblings(':not(.slick-current)').find('.state-slider__label--is-active');

		if (sibling) {
			this.toggleTabState(sibling);
		}

		this.handleTabChange(element);
		this.toggleTabState(element);
	}

	handleTabChange(element) {
		const id = element.attr('href');
		const current = $(id);
		const prev = $(id).parent().find('.state-slider__panel[aria-hidden="false"]');

		if (prev) {
			this.togglePanelState(prev);
		}
		
		this.togglePanelState(current);
	}

	togglePanelState(element) {
		element.attr('aria-hidden', (i, txt) => (txt === 'false') ? 'true' : 'false');

		if (element.attr('aria-hidden') === 'true') {
			element.hide();
		} else {
			element.fadeIn(this.options.duration);
		}
	}

	toggleTabState(element) {
		element.toggleClass('state-slider__label--is-active');
	}
}
