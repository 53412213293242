import { dirButton, mql } from '../lib/utils';

export const waterTips = {
	elem: {
		$waterTips: $('.water-tips').length > 0 ? $('.water-tips') : null
	},

	options: {
		adaptiveHeight: false,
		arrows: true,
		dots: true,
		draggable: false,
		inifinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		variableWidth: true,
		speed: 425,
		rows: 0,
		nextArrow: dirButton('', 'btn btn--large btn--rnd', 'Go to the next slide'),
		prevArrow: dirButton('', 'btn btn--large btn--rnd dir-btn--prev', 'Go to the previous slide'),
		responsive: [
			{
				breakpoint: 9999,
				settings: 'unslick'
			},
			{
				breakpoint: 1024,
				settings: {
					centerMode: true,
					variableWidth: true,
				}
			},
			{
				breakpoint: 500,
				settings: {
					centerMode: false,
					variableWidth: false,
				}
			}
		]
	},

	init() {
		if (this.elem.$waterTips !== null) {
			this.bindUIActions();
			this.onResize(mql.large);
		}
	},

	bindUIActions() {
		if (!this.elem.$waterTips.hasClass('water-tips--large')) {
			this.options.responsive.splice(0, 2); // Remove the following indexes (breakpoints): [0, 1]
			$('.water-tips__cards', this.elem.$slider).slick(this.options);
		}

		$('.water-tips__cards', this.elem.$slider).on('mouseleave', '.dir-btn', e => $(e.target).blur());

		mql.large.addListener(this.onResize.bind(this));
	},

	onResize(mq) {
		if (!mq.matches) {	
			$('.water-tips__cards', this.elem.$slider).not('.slick-initialized').slick(this.options);
		}
	}
};
