// Lib
import { ready } from './lib/utils';
import { plugin } from './lib/plugin';
// Modules
import { forms } from './modules/forms';
import { masthead } from './modules/masthead';
import { media } from './modules/media';
import { modal } from './modules/modal';
import { statsState } from './modules/stats-state';
import { waterTips } from './modules/water-tips';
// Plugins
import Accordion from './modules/accordion';
import Popover from './modules/popover';
import StateSlider from './modules/state-slider';
import Table from './modules/table';

const level = {

	elem: {
		$accordion: $('.accordion'),
		$popoverButton: $('.popover-btn'),
		$table: $('.non-wysiwyg-table .non-wysiwyg-table__wrapper'),
		$tabs: $('.state-slider__tabs .state-slider__tabs-wrapper')
	},

	init() {
		// Init plugins
		plugin('Accordion', Accordion);
		plugin('Popover', Popover);
		plugin('StateSlider', StateSlider);
		plugin('Table', Table);

		// Modules
		forms.init();
		masthead.init();
		media.init();
		modal.init();
		statsState.init();
		waterTips.init();

		this.bindUIActions();
	},

	bindUIActions() {
		// Plugins
		this.elem.$accordion.Accordion();
		this.elem.$popoverButton.Popover();
		this.elem.$tabs.StateSlider();
		this.elem.$table.Table();
	}

};

ready(function () {
	level.init();
});