import { dirButton } from '../lib/utils';

export const media = {

	elem: {
		$slider: $('.media__items').length > 0 ? $('.media__items') : null
	},

	options: {
		adaptiveHeight: true,
		arrows: true,
		dots: false,
		draggable: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 425,
		rows: 0,
		lazyLoad: 'progressive',
		nextArrow: (function () {
			const style = ($('.media').hasClass('media--aqua')) ? 'btn--rnd' : 'btn--square';
			return dirButton('', `btn ${style} btn--large`, 'Go to the next slide');
		}()),
		prevArrow: (function () {
			const style = ($('.media').hasClass('media--aqua')) ? 'btn--rnd' : 'btn--square';
			return dirButton('', `btn ${style} btn--large dir-btn--prev`, 'Go to the previous slide');
		}())
	},

	init() {
		if (this.elem.$slider !== null) {
			this.bindUIActions();
		}
	},

	bindUIActions() {
		this.elem.$slider.slick(this.options);
		this.elem.$slider.on('mouseleave', '.dir-btn', e => $(e.target).blur());
	}

};