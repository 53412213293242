import { mql } from '../lib/utils';

export const statsState = {

	elem: {
		$statsState: $('.stats-state').length > 0 ? $('.stats-state') : null
	},

	init() {
		if (this.elem.$statsState === null) {
			return;
		}

		this.bindUIActions();
		this.gridHeight(mql.medium);
	},

	bindUIActions() {
		this.elem.$statsState.on('click', '.more-btn', this.onClick.bind(this));

		mql.medium.addListener(this.gridHeight.bind(this));
	},

	onClick(e) {
		e.preventDefault();

		const $target = $(e.target);
		const $statsState = $target.closest('.stats-state');
		const $grid = $statsState.find('.stats-state__grid');

		$target
			.toggleClass('more-btn--is-active')
			.attr('aria-pressed', (i, txt) => (txt === 'false') ? 'true' : 'false')
			.find('.text').text((i, txt) => (txt === 'See More') ? 'See Less' : 'See More');
		
		if ($grid.hasClass('stats-state__grid--is-active') === true) {
			$grid.height($grid.data('height-trunc'));
			$grid.removeClass('stats-state__grid--is-active');

			$('html, body').animate({ scrollTop: $statsState.offset().top }, 425);
		} else {
			$grid.height($grid.data('height-full'));
			$grid.addClass('stats-state__grid--is-active');
		}	
	},

	gridHeight(mq) {
		this.elem.$statsState.find('.stats-state__grid').attr('style', '');

		if (mq.matches) {
			return;
		}

		this.elem.$statsState.find('.stats-state__grid').each(function () {
			let fullHeight = this.offsetHeight;
			let minHeight = 16;
			
			$(this).find('.stats-block:lt(3)').each(function () {
				minHeight += $(this).outerHeight(true);
			});

			$(this).attr('style', ''); // First remove any inline styles that might be currently affecting the height.

			if ($(this).find('.stats-block').length > 3) {
				$(this).data('height-full', fullHeight);
				$(this).data('height-trunc', minHeight);
			}
			
			if ($(this).hasClass('stats-state__grid--is-active') === true) {
				$(this).height($(this).data('height-full'));
			} else {
				$(this).height($(this).data('height-trunc'));
			}

		});
	}

};